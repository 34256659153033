import React from 'react'
import moment from 'moment'
import Layout from "../../components/Layout"
import { graphql, Link } from 'gatsby'
import DocumentCard from "../../components/DocumentCard"
import prettyBytes from "pretty-bytes"

class DocumentPageTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.agendas = props.data.allStrapiAgendas.nodes
    this.minutes = props.data.allStrapiMinutes.nodes
    this.documentsDate = props.data.documentsDate.nodes
    this.documentsAZ = props.data.documentsAZ.nodes
    
    this.agendasRange = this.getYearRange(this.agendas)
    this.minutesRange = this.getYearRange(this.minutes)

    this.state = {
      navSet: "Agendas",
      sort: "Date",
      agendasYear: this.agendasRange.max,
      minutesYear: this.minutesRange.max
    }

    this.YearNav = this.YearNav.bind(this)
    this.setYear = this.setYear.bind(this)
    this.getCards = this.getCards.bind(this)
    this.setSort = this.setSort.bind(this)
  }

  setNav(val) {
    if(val) {
      this.setState({ navSet: val})
    }
  }

  setYear(year, type) {
    if(type === "Agendas") {
      this.setState({ agendasYear: year})
    } else if( type === "Minutes") {
      this.setState({ minutesYear: year})
    }
  }

  setSort(sortto) {
      this.setState({ sort: sortto})
  }
  getYearRange(array) {
    let min = new Date(array[0].meetingTime).getFullYear()
    let max = 0

    array.map( x => {
      let year = new Date(x.meetingTime).getFullYear()
      min = year < min ? year : min
      max = year > max ? year : max
    })

    return { min: min, max: max }
  }

  YearNav({set}) {
    let yearset = [ ];

    if(set === "Agendas") {
      for(let i = this.agendasRange.max; i >= this.agendasRange.min; i--) {
          yearset.push(i)
      }
    return(<div style={{marginBottom: '1rem'}}>
        <p>Select Year</p>
        <div className="buttons is-centered">
        {yearset.map(year => (
          <button 
            onClick={() => this.setYear(year, set)}
            className={'button '+( this.state.agendasYear == year ? 'is-primary' : '')}>
            {year}
          </button>
        ))}
        </div>
      </div>)
    }
    else if(set === "Minutes") {
      for(let i = this.minutesRange.max; i >= this.minutesRange.min; i--) {
          yearset.push(i)
      }
    return(<div style={{marginBottom: '1rem'}}>
        <p>Select Year</p>
        <div className="buttons is-centered">
        {yearset.map(year => (
          <button 
            onClick={() => this.setYear(year, set)}
            className={'button '+( this.state.minutesYear == year ? 'is-primary' : '')}>
            {year}
          </button>
        ))}
        </div>
      </div>)
    } else {
      return(
        <div style={{marginBottom: '1rem'}}>
          <p>Sort By</p>
          <div className="buttons is-centered">
            <button onClick={() => this.setSort('Date')} className={'button '+( this.state.sort == 'Date' ? 'is-primary' : '')}>Date Published</button>
            <button onClick={() => this.setSort('A-Z')} className={'button '+( this.state.sort == 'A-Z' ? 'is-primary' : '')}>A-Z</button>
          </div>
        </div>
      )
    }
    
  }

  getCards() {
    if( this.state.navSet === "Agendas") {
      return (
        <>
        {this.agendas
          .filter(agenda => new Date(agenda.meetingTime).getFullYear() === this.state.agendasYear )
          .map(agenda =>(
          <DocumentCard 
            key={agenda.id}
            title={`${agenda.meetingType} Meeting Agenda`}
            subtitle={moment(agenda.meetingTime).format("lll")}
            rootLink={agenda.document.localFile.publicURL}
            rootLinkName={`View Agenda`}
            rootLinkSize={agenda.document.localFile.prettySize}
            attachments={agenda.attachments}
          />
        ))}
        </>
      )
    }else if( this.state.navSet === "Minutes") {
      return (
        <>
        {this.minutes
          .filter(minutes => new Date(minutes.meetingTime).getFullYear() === this.state.minutesYear )
          .map(minutes =>(
          <DocumentCard 
            key={minutes.id}
            title={`${minutes.meetingType} Meeting Minutes`}
            subtitle={moment(minutes.meetingTime).format("ll")}
            rootLink={minutes.document.localFile.publicURL}
            rootLinkName={`View Minutes`}
            rootLinkSize={minutes.document.localFile.prettySize}
            attachments={minutes.attachments}
          />
        ))}
        </>
      )

    } else {
      if (this.state.sort === 'Date') {
        return (
          <>
          { this.documentsDate.map(document =>(
            <div key={document.id} className="document-grid-item">
              <div className="card" style={{marginBottom: '1rem'}}>
                <div className="card-content">
                  <p className="is-size-6">{document.title}<br />
                  <small className="has-text-grey">{moment(document.publishedAt).format("ll")}</small>
                  </p>
                </div>
                <div className="card-footer">
                  <a href={document.document.localFile.publicURL} target="_blank" className="card-footer-item">View Document</a>
                  <div className="card-footer-item">{document.document.localFile.prettySize}</div>
                </div>
                { document.attachments.length > 0 ? (
                  <>{document.attachments.map(attachment => (
                    <div key={attachment.id} className="card-footer">
                      <a href={attachment.document.url} target="_blank" className="card-footer-item">{attachment.title}</a>
                      <div className="card-footer-item">{prettyBytes(attachment.document.size*1000)}</div>
                    </div>
                  ))}</>
                ) : (<></>)}
              </div>
            </div>
          ))}
          </>
        )
      } else {
        return (
          <>
          { this.documentsAZ.map(document =>(
            <div key={document.id} className="document-grid-item">
              <div className="card" style={{marginBottom: '1rem'}}>
                <div className="card-content">
                  <p className="is-size-6">{document.title}<br />
                  <small className="has-text-grey">{moment(document.publishedAt).format("ll")}</small>
                  </p>
                </div>
                <div className="card-footer">
                  <a href={document.document.localFile.publicURL} target="_blank" className="card-footer-item">View Document</a>
                  <div className="card-footer-item">{document.document.localFile.prettySize}</div>
                </div>
                { document.attachments.length > 0 ? (
                  <>{document.attachments.map(attachment => (
                    <div key={attachment.id} className="card-footer">
                      <a href={attachment.document.url} target="_blank" className="card-footer-item">{attachment.title}</a>
                      <div className="card-footer-item">{prettyBytes(attachment.document.size*1000)}</div>
                    </div>
                  ))}</>
                ) : (<></>)}
              </div>
            </div>
          ))}
          </>
        )
      }
    }
  }

  render() {


  return (
        <Layout>
          <div className="container">
          <div className="section">
          <div className="columns">
          <div className="column is-7 is-offset-1">
            <h1 className="title is-size-3">Public Documents</h1>
          <div className="content">
            <h3>Meetings</h3>
            <p>The District generally meets on the third Tuesday of each month at 12:00 p.m.  at the offices of Schwartz Page &amp; Harding, 1300 Post Oak Blvd., Suite 1400, Houston, Texas 77056. The Board of Directors invites all members of the public to attend its meetings.</p>
            <p>Agendas for District meetings are posted on this page approximately one week before the meeting. Please check this page for the agenda or <a href="mailto:info@touchstonedistrictservices.com?subject=ricewood mud agenda inquiry">email the District here</a> if you need confirmation of a Board meeting. Residents of the district have the right to request the designation of a meeting location within the district under <a target="_blank" rel="noopener noreferrer" href="https://statutes.capitol.texas.gov/Docs/WA/htm/WA.49.htm#49.062">Section 49.062, Water Code</a>. A description of this process can be found at <a target="_blank" rel="noopener noreferrer" href="https://www.tceq.texas.gov/waterdistricts">https://www.tceq.texas.gov/waterdistricts</a></p>
          </div>
          </div>
          </div>
          <div className="content-section" style={{ maxWidth: '100%'}}>
            <div className="tabs is-centered">
              <ul>
                <li onClick={ () => this.setNav("Agendas") }
                  className={this.state.navSet === "Agendas" ? "is-active" : "" } >
                  <a>Agendas</a>
                </li>
                <li onClick={ () => this.setNav("Minutes") }
                  className={this.state.navSet === "Minutes" ? "is-active" : "" }> 
                  <a>Minutes</a>
                </li>
                <li onClick={ () => this.setNav("Documents") }
                  className={this.state.navSet === "Documents" ? "is-active" : "" }>
                  <a>Documents</a>
                </li>
              </ul>
            </div>

            <div className="has-text-centered">
              <this.YearNav set={this.state.navSet} />
            </div>

            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="document-grid">
                  <this.getCards />
                </div>
              </div>
            </div>
          </div>
          </div>
          </div>
        </Layout>
  )
  }
} 


export default DocumentPageTemplate


export const documentPageQuery = graphql`
  query documentPageById {
    allStrapiAgendas(sort: {order: DESC, fields: meetingTime}) {
      nodes {
        id
        meetingTime
        meetingType
        document {
          localFile {
            publicURL
            prettySize
          }
        }
        attachments {
          id
          title
          document {
            url
            size
          }
        }
      }
    }
    allStrapiMinutes(sort: {order: DESC, fields: meetingTime}) {
      nodes {
        id
        meetingTime
        meetingType
        document {
          localFile {
            publicURL
            prettySize
          }
        }
        attachments {
          id
          title
          document {
            url
            size
          }
        }
      }
    }
    documentsDate: allStrapiDocuments(sort: {order: DESC, fields: publishedAt}) {
      nodes {
        id
        title
        publishedAt
        document {
          localFile {
            publicURL
            prettySize
          }
        }
        attachments {
          id
          title
          document {
            url
            size
          }
        }
      }
    }
    documentsAZ: allStrapiDocuments(sort: {order: ASC, fields: title}) {
      nodes {
        id
        title
        publishedAt
        document {
          localFile {
            publicURL
            prettySize
          }
        }
        attachments {
          id
          title
          document {
            url
            size
          }
        }
      }
    }
  }
`